import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { withTranslation } from 'react-i18next';


// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
} from "reactstrap";
import {userCan, userCant} from "../../../services/Authorization/authService";
import {connect} from "react-redux";

class AddRentalCompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      projectId: "ettest",
      rentalCompanyToAddValue: '',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      deleteRentalCompanyAlert: null,
      unableToDeleteRentalCompanyAlert: null,
      responseOnDeleteRequest: 'null',
      visible: null
    }
    this.createNewRentalCompany = this.createNewRentalCompany.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteRentalCompanyById = this.deleteRentalCompanyById.bind(this)
    this.deleteRentalCompanyAlert = this.deleteRentalCompanyAlert.bind(this)
    this.deleteRentalCompanyById = this.deleteRentalCompanyById.bind(this)
    this.unableToDeleteRentalCompanyAlert = this.unableToDeleteRentalCompanyAlert.bind(this)
    this.warningRentalCompanyCantBeDeleted = this.warningRentalCompanyCantBeDeleted.bind(this)
  }


  createNewRentalCompany(element) {

    axios.post(process.env.REACT_APP_API_URL + "/api/rentalcompany/", {
      createdDate: moment(new Date()).format(),
      name: element,
      projectId: this.props.projectId
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        axios.get(process.env.REACT_APP_API_URL + "/api/rentalcompany/", {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            this.setState({
              data: res.data,
              rentalCompanyToAddValue: ''
            }, () => this.state.data);
            this.props.getSomeRentalCompanies()
            return res.data
          })
      })
      .catch((error) => {
        ;
      })
  }


  handleRentalCompanyToAddText = (e) => {

    this.setState({ rentalCompanyToAddValue: e.target.value }, () => this.state.rentalCompanyToAddValue);
  }


  componentWillReceiveProps({ rentalCompanyTableData }) {
    this.setState({ ...this.state, rentalCompanyTableData }, () => this.state)
  }
  dataTablelength(obj) {
    return Object.keys(obj).length;
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.rentalCompanyTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };

  renderEditable(cellInfo) {
    const cellValue = this.props.rentalCompanyTableData?.[cellInfo.index]?.[cellInfo.column.id];
    
    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask=""
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue || ''}
            tag={InputMask}
          />
        ) : (
          <div>
            {cellValue || ''}
          </div>
        )}
      </div>
    );
  }

  saveRowData(cellInfo) {

    this.setState({ editContent: false }, () => console.log(cellInfo.original.name))

    axios.put(process.env.REACT_APP_API_URL + '/api/rentalcompany/', {
      _id: cellInfo.original._id,
      name: cellInfo.original.name,
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.setState({
        editingRow: -1,
        isDirty: false
      })
      return res.data
    })
      .catch((error) => {
        ;
      })
  }


  toggleEditable(index) {
    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }


  renderActiveButtons(row) {

    if (userCant(this.props.user, 'equipment.manage')) {
      return '';
    }

    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.index)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        onClick={() => this.saveRowData(row)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-save"
        /></Button>
      <Button
        color="danger"
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteRentalCompanyAlert(row.original)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
    </div>)


  }
  deleteRentalCompanyById(rentalId) {
    var self = this
    console.log(rentalId)
    axios.delete(process.env.REACT_APP_API_URL + '/api/rentalCompany/' + rentalId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.setState({
        deleteRentalCompanyAlert: null
      }, () => { })
      this.props.getSomeRentalCompanies()
      return res
    })

      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 403 || error.response.status === 409) {
            self.warningRentalCompanyCantBeDeleted(error.response)
            self.setState({ deleteRentalCompanyAlert: null }, () => { })
            console.log(error.response);
            //  return self.setState({responseOnDeleteRequest:'Delete associated rental equipment first'},()=>{})
          }
          console.log(error.response.status);

        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

    // .catch((error) => {
    //   console.log(error)
    //

    // })
  }

  deleteRentalCompanyAlert(rentalId) {

    this.setState({
      deleteRentalCompanyAlert:
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => this.deleteRentalCompanyById(rentalId._id)}
          onCancel={() => this.setState({ deleteRentalCompanyAlert: null }, () => { })}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={this.props.t("Delete")}
          cancelBtnText={this.props.t("Cancel")}
          showCancel
        >
          {this.props.t("Delete Rental Company")} {rentalId?.name}

        </ReactBSAlert>
    }, () => { })
  }

  warningRentalCompanyCantBeDeleted(response) {
    // console.log(response)
    this.setState({
      visible:
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => this.setState({ visible: null }, () => { })}
          // onCancel={() => this.setState({ deleteRentalCompanyAlert: null }, () => { })}
          confirmBtnBsStyle="info"
          // cancelBtnBsStyle="danger"
          confirmBtnText={this.props.t("Close")}
        // cancelBtnText="Cancel"
        // showCancel
        >
          <p>{this.props.t("Unable to delete rental company as its associated with equipment")}.
            {this.props.t("Please delete the equipment first")}.
          </p>
          {/* <p>Detail:</p> */}
          {/*
         <p>{response?.data?.rentalEquipment?.company }</p>
         <p>{response?.data?.rentalEquipment?.description}</p>
         <p>{response?.data?.rentalEquipment?.typeModel}</p> */}
        </ReactBSAlert>
    }, () => { })
  }

  unableToDeleteRentalCompanyAlert(rentalId) {
    alert(this.props.t('unable to delete: rental company in use'))
  }


  render() {
    return (
      <>
        {this.state.deleteRentalCompanyAlert}
        {this.state.visible}
        <div className="content">
          {userCan(this.props.user, 'equipment.manage') && <>
            <h4>{this.props.t("Add Rental Company")}</h4>
            <Row>
              <Col md={9}>
                <Input type="text" onChange={this.handleRentalCompanyToAddText}/>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Button color="info"
                        onClick={() => this.createNewRentalCompany(this.state.rentalCompanyToAddValue)}>{this.props.t("Add Rental Company")}</Button>
              </Col>
            </Row>
          </>}
          <ReactTable
            data={this.props.rentalCompanyTableData}
            columns={[
              {
                Header: this.props.t("Rental Equipment"),
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Rental Equipment Company"),
                accessor: (row) => row.rentalCompany?.name ?? '',
                id: "name",
                Cell: this.renderEditable
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value)))
              }
            ]}
            defaultPageSize={1}
            pageSize={this.props.rentalCompanyTableData?.length || 1}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
          />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}
export default withTranslation()(connect(mapStateToProps, null)(AddRentalCompanyTable))
